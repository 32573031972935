export const SET_EXPIRATION_DATE = (state) => {
    const date = new Date();
    date.setHours(date.getHours() + 8);
    state.expirationDate = date;
}

export const EXTEND_EXPIRATION_DATE = (state) => {
    const date = new Date(state.expirationDate);
    state.expirationDate = date.setDate(date.getDate())
}

export const RESET_AANBOD = (state) => state.aanbod = []

export const ADD_AANBOD = (state, payload) => {
    state.aanbod.push(payload)
}

export const DELETE_AANBOD = (state, payload) => {
    state.aanbod.splice(state.aanbod.map(instrument => instrument.uuid).indexOf(payload.uuid), 1)
}
