<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<style lang="scss">
@import "./styles/_variables.scss";
@import "./styles/global.scss";
</style>

<script>
import UserBehaviourService from '@/services/UserBehaviourService';
import { mapActions } from 'vuex';

export default {
    name: "App",
    mounted() {
        this.checkVersion();
        this.addIUBenda();
    },
    watch: {
        $route() {
            UserBehaviourService.addGeneralAction('navigated');
        }
    },
    methods: {
        ...mapActions('version', [
            'checkVersion'
        ]),
        addIUBenda() {
            let iubenda = document.createElement("script");
            window._iub = window._iub || [];
            _iub.csConfiguration = {
                consentOnContinuedBrowsing: false,
                whitelabel: true,
                lang: "nl",
                siteId: 2203736,
                gdprAppliesGlobally: false,
                cookiePolicyId: 83269323,
                banner: {
                    acceptButtonDisplay: true,
                    customizeButtonDisplay: true,
                    rejectButtonDisplay: true,
                    position: "bottom",
                    acceptButtonColor: "#010101",
                    acceptButtonCaptionColor: "white",
                    customizeButtonColor: "#212121",
                    customizeButtonCaptionColor: "#ffffff",
                    rejectButtonColor: "#010101",
                    rejectButtonCaptionColor: "white",
                    textColor: "white",
                    backgroundColor: "#454545"
                }
            };
            iubenda.setAttribute("src", "https://cdn.iubenda.com/cs/iubenda_cs.js");
            document.head.appendChild(iubenda);
        }
    },
};
</script>
