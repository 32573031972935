import axios from 'axios';
import ElasticSearch from './ElasticSearch';

const index_regios = 'regions';

export default class RegionsES extends ElasticSearch {
    static get indexRegios() {
        return this.applyIndexPrefix(index_regios);
    }

    static getRegions()
    {
        const index = RegionsES.indexRegios;
        const data = {
            from: 0,
            size: 100
        };

        return axios.post(`/${this.endpoint}/${index}/_search`, data);
    }

    static getRegion(slug) {
        const index = RegionsES.indexRegios;
        const data = {
            query: {
                bool: {
                    must: [
                        { match: { slug: slug } }
                    ]
                }
            }
        };

        return axios.post(`/${this.endpoint}/${index}/_search`, data);
    }
}
