<template>
    <v-btn
        v-bind="attributes"
        v-on="$listeners"
        class="full-primary white--text"
        dark
        @click.native="followButton"
    >
        <slot/>
    </v-btn>
</template>

<script>
import UserBehaviourService from '@/services/UserBehaviourService'
import router from '@/router'

const getChildrenTextContent = function (children) {
  return children.map(function (node) {
    return node.children
      ? getChildrenTextContent(node.children)
      : node.text
  }).join('')
}

export default {
  name: "PrimaryButton",
  computed: {
    attributes () {
      // eslint-disable-next-line no-unused-vars
      const { to, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    followButton () {
      this.track()
      if (this.$attrs.to) {
        router.push(this.$attrs.to)
      }
    },
    track () {
      const button = this.$slots?.default ? getChildrenTextContent(this.$slots.default) : ''
      UserBehaviourService.addGeneralAction('button clicked', {
        value: button
      })
    }
  }
}
</script>

<style scoped lang="scss">
.full-primary {
  text-transform: none;
  background-color: var(--primary) !important;
  border-color: var(--primary);

  &::v-deep .v-btn__content {
    max-width: 100%;
  }
}
</style>
