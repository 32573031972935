import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import svgJs from './plugins/vueSvgPlugin'
import ReactiveSearch from '@appbaseio/reactivesearch-vue'
import VueYouTubeEmbed from 'vue-youtube-embed'
import moment from 'moment';

Vue.use(VueYouTubeEmbed)
Vue.use(svgJs)
Vue.use(ReactiveSearch)

Vue.config.productionTip = false

Vue.filter('date', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
    }
});

Vue.filter('dateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YYYY hh:mm')
    }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
