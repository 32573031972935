import providers from './modules/providers';
import regions from './modules/regions';
import tiles from './modules/tiles';
import townships from './modules/townships';

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        providers,
        regions,
        tiles,
        townships
    },
}
