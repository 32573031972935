export const Zoeken = state => state.Zoeken
export const Regio = state => state.Regio
export const Gemeente = state => state.Gemeente
export const Leeftijd = state => !Array.isArray(state.Leeftijd) ? state.Leeftijd : ''
export const Dienstverband = state => !Array.isArray(state.Dienstverband) ? state.Dienstverband : ''
export const Doelgroepregister = state => !Array.isArray(state.Doelgroepregister) ? state.Doelgroepregister : ''
export const Doelgroep = state => !Array.isArray(state.Doelgroep) ? state.Doelgroep : ''
export const Tegel = state => state.Tegel
export const Reach = state => Array.isArray(state.Reach) && state.Reach.length > 0 ? state.Reach.map((r) => r.value) : [];
export const Sector = state => !Array.isArray(state.Sector) ? state.Sector : ''
