<template>
    <div>
        <DefaultLeftDrawer
            :show="drawer.left"
        />

        <DefaultTopBar
            @toggle-drawer-left="drawer.left = !drawer.left"
            @toggle-drawer-right="drawer.right = !drawer.right"
        />

        <!-- Sizes your content based upon application components -->
        <v-main>
            <!-- Provides the application the proper gutter -->
            <v-container fluid>
                <!-- If using vue-router -->
                <router-view></router-view>
            </v-container>
        </v-main>

        <DefaultRightDrawer
            :show="drawer.right"
            @toggle="drawer.right = !drawer.right"
        />
    </div>
</template>

<script>
    import DefaultTopBar from './default/DefaultTopBar';
    import DefaultLeftDrawer from './default/DefaultLeftDrawer';
    import DefaultRightDrawer from './default/DefaultRightDrawer';

    export default {
        name: "DefaultLayout",
        components: { DefaultRightDrawer, DefaultLeftDrawer, DefaultTopBar },
        data: () => ({
            drawer: {
                left: null,
                right: null
            },
        }),
    }
</script>
