import axios from 'axios';
import ElasticSearch from './ElasticSearch';

const index_providers = 'providers';

export default class ProvidersES extends ElasticSearch {
    static get indexProviders() {
        return this.applyIndexPrefix(index_providers);
    }

    static getProviders()
    {
        const index = ProvidersES.indexProviders;
        const data = {
            from: 0,
            size: 100
        };

        return axios.post(`/${this.endpoint}/${index}/_search`, data);
    }
}
