import ElasticSearch from '@/services/ElasticSearch';
import axios from 'axios';
import store from '../store/index';
import AnonymousUserIdentifierService from '@/services/AnonymousUserIdentifierService';
import Cookies from 'js-cookie';

const environment = process.env.VUE_APP_ENV;
const protocol = process.env.VUE_APP_ELASTIC_BEHAVIOUR_PROTOCOL;
const path = process.env.VUE_APP_ELASTIC_BEHAVIOUR_PATH;
const port = process.env.VUE_APP_ELASTIC_BEHAVIOUR_PORT;
const endpoint = process.env.VUE_APP_ELASTIC_BEHAVIOUR_ENDPOINT;

const generalInteractionIndex = 'general_interaction';
const resultInteractionIndex = 'result_interaction'
const searchInteractionIndex = 'search_interaction';

export default class UserBehaviourService extends ElasticSearch
{
    static get protocol()
    {
        const isDevEnvironment = environment === 'development';
        return isDevEnvironment ? 'http' : protocol;
    }

    static get path()
    {
        return path;
    }

    static get port()
    {
        return port;
    }

    static get endpoint() {
        return endpoint ? endpoint : 'api/elastic-behaviour';
    }

    static get searchState() {
        const regions = store.getters['filters/Regio'];
        const regionsCount = regions ? regions.length : 0
        const townships = store.getters['filters/Gemeente'];
        const townshipsCount = townships ? townships.length : 0;
        const age = store.getters['filters/Leeftijd'];
        const ageCount = age.length === 0 ? 0 : 1;
        const employmentType = store.getters['filters/Dienstverband'];
        const employmentTypeCount = employmentType.length === 0 ? 0 : 1;
        const targetGroupRegister = store.getters['filters/Doelgroepregister'];
        const targetGroupRegisterCount = targetGroupRegister.length === 0 ? 0 : 1;
        const tiles = store.getters['filters/Tegel'];
        const tilesCount = tiles ? tiles.length : 0;
        const targetGroup = store.getters['filters/Doelgroep'];
        const targetGroupCount = targetGroup.length === 0 ? 0 : 1
        const reaches = store.getters['filters/Reach'];
        const reachesCount = reaches ? reaches.length : 0;
        const sectors = store.getters['filters/Sector'];
        const sectorsCount = sectors.length === 0 ? 0 : 1;

        const totalFilterCount = regionsCount + townshipsCount + ageCount + employmentTypeCount + targetGroupRegisterCount + tilesCount + targetGroupCount + reachesCount + sectorsCount;

        const searchQuery = store.getters['filters/Zoeken']
        const queryOnlyWhitespace = /^\s*$/.test(searchQuery)

        return {
            query: searchQuery,
            queryEmpty: queryOnlyWhitespace,
            filters: {
                regions: {
                    values: regions,
                    count: regionsCount
                },
                townships: {
                    values: townships,
                    count: townshipsCount
                },
                age: {
                    values: age,
                    count: ageCount
                },
                employmentType: {
                    values: employmentType,
                    count: employmentTypeCount
                },
                targetGroupRegister: {
                    values: targetGroupRegister,
                    count: targetGroupRegisterCount
                },
                tiles: {
                    values: tiles,
                    count: tiles ? tiles.length : 0
                },
                targetGroup: {
                    values: targetGroup,
                    count: targetGroupCount
                },
                reaches: {
                    values: reaches,
                    count: reaches ? reaches.length : 0
                },
                sector: {
                    values: sectors,
                    count: sectorsCount
                },
            },
            filtersCount: totalFilterCount,
            filtersEmpty: totalFilterCount === 0
        }
    }

    static get url() {
        return window.location.origin + window.location.pathname;
    }

    static get queryParams() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return Array.from(urlSearchParams.entries()).map(v => ({'param': v[0], 'value': v[1]}));
    }

    static addGeneralAction(action, actionData = {}) {
        if (!this.elasticPath) {
            return null;
        }
        const data = {
            ...this.buildDefaultPayload('previous_general_interaction'),
            action,
            actionData
        }
        return axios.post(`/${this.endpoint}/${this.applyIndexPrefix(generalInteractionIndex)}/_doc/`, data);
    }

    static addResultAction (action, actionData = {}) {
        if (!this.elasticPath) {
            return null
        }
        const data = {
            ...this.buildDefaultPayload('previous_result_interaction'),
            search: this.searchState,
            action,
            actionData
        }
        return axios.post(`/${this.endpoint}/${this.applyIndexPrefix(resultInteractionIndex)}/_doc/`, data)
    }

    static addSearchAction(action, actionData = {}) {
        if (!this.elasticPath) {
            return null;
        }
        const data = {
            ...this.buildDefaultPayload('previous_search_interaction'),
            search: this.searchState,
            action,
            actionData
        }
        return axios.post(`/${this.endpoint}/${this.applyIndexPrefix(searchInteractionIndex)}/_doc/`, data);
    }

    static buildDefaultPayload (timeStampCookieName = null) {
        const dateCurrentAction = new Date()
        const payload = {
            timestamp: dateCurrentAction.toISOString(),
            anonymous_id: AnonymousUserIdentifierService.getIdentifier(),
            location: {
                url: this.url,
                params: this.queryParams
            }
        }

        if (timeStampCookieName !== null) {
            const cookieDate = Cookies.get(timeStampCookieName)
            const datePreviousAction = cookieDate ? new Date(cookieDate) : null
            payload.previous = {
                timestamp: datePreviousAction ? datePreviousAction.toISOString() : null,
                seconds_ago: datePreviousAction ? (datePreviousAction.getTime() - dateCurrentAction.getTime()) / 1000 : null
            }
            Cookies.set(timeStampCookieName, dateCurrentAction)
        }
        return payload
    }
}
