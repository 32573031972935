<template>
    <v-badge
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot />
    </v-badge>
</template>

<script>
export default {
    name: "Badge"
}
</script>

<style scoped>
.badge {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}
</style>
