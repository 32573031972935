<template>
    <svg width="96"
         height="50"
         viewBox="150 15 96 50"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path id="a" d="M57.396.211H.281v49.405h57.115V.21z"/>
            <path id="c" d="M57.564.211H.448v49.405h57.116V.21z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(187.931 15)">
                <mask
                    id="b"
                    fill="#ffffff"
                >
                    <use xlink:href="#a"/>
                </mask>
                <path
                    d="M57.396 24.913c0-13.573-11.004-24.622-24.56-24.7-.024 0-.048-.003-.073-.003H24.75a2.504 2.504 0 1 0 0 5.01h7.945c10.858 0 19.694 8.834 19.694 19.693 0 10.86-8.836 19.694-19.694 19.694H5.289v-4.578H.281v9.587h32.482v-.001c13.59-.04 24.633-11.105 24.633-24.702"
                    class="logo-border"
                    fill="#ffffff"
                    mask="url(#b)"
                />
            </g>
            <g transform="translate(150 15)">
                <mask
                    id="d"
                    fill="#ffffff"
                >
                    <use xlink:href="#c"/>
                </mask>
                <path
                    d="M.448 24.913c0 13.573 11.004 24.622 24.56 24.7.024 0 .048.003.073.003h8.014a2.504 2.504 0 1 0 0-5.009h-7.944c-10.859 0-19.694-8.834-19.694-19.694 0-10.859 8.835-19.694 19.694-19.694h27.404v4.578h5.009V.21H25.08v.001C11.49.251.448 11.316.448 24.913"
                    class="logo-border"
                    fill="#ffffff"
                    mask="url(#d)"
                />
            </g>
            <path
                class="logo-text"
                fill="#ffffff"
                d="M176.017 42.116l-4.678-12.401h-5.664l8.14 20.366h3.921l8.401-20.366h-5.363zm26.542.558l-7.928-12.959h-6.413v20.367h4.997V36.787l8.15 13.295h6.19V29.715h-4.996zm19.323-4.94v4.663h3.537v2.677a9.773 9.773 0 0 1-1.414.54c-.652.203-1.443.306-2.354.306-.88 0-1.686-.15-2.394-.448a5.338 5.338 0 0 1-1.824-1.247 5.522 5.522 0 0 1-1.17-1.894c-.272-.729-.41-1.549-.41-2.433 0-.867.138-1.681.41-2.42a5.502 5.502 0 0 1 1.17-1.908 5.374 5.374 0 0 1 1.824-1.246c.709-.298 1.514-.449 2.394-.449 1.064 0 1.959.151 2.657.449.7.297 1.338.74 1.898 1.318l.242.248 3.515-3.835-.237-.221a8.923 8.923 0 0 0-3.627-2.038c-1.35-.386-2.847-.583-4.448-.583-1.572 0-3.044.25-4.373.745a10.139 10.139 0 0 0-3.481 2.143c-.975.928-1.746 2.066-2.29 3.384-.543 1.315-.819 2.8-.819 4.413 0 1.613.276 3.098.82 4.413.545 1.319 1.314 2.457 2.289 3.384.974.926 2.146 1.647 3.48 2.143 1.33.494 2.802.744 4.374.744 1.464 0 2.92-.152 4.33-.452a16.054 16.054 0 0 0 4.09-1.478l.177-.09V37.733h-8.366z"
            />
        </g>
    </svg>

</template>

<style>
    .logo-border {
        fill: var(--logo-border, #009FE4);
    }
    .logo-text{
        fill: var(--logo-text, #004388);
    }
</style>

<script>
    export default {
        name: "VngLogo"
    }

    // <v-img
    // src="@/assets/vng-logo.svg"
    // lazy-src="@/assets/vng-logo.svg"
    // max-width="80"
    // max-height="40"
    //     ></v-img>


</script>
