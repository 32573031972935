export const SET_ZOEKEN = (state, payload) => { state.Zoeken = payload }
export const SET_REGIO = (state, payload) => { state.Regio = payload }
export const SET_GEMEENTE = (state, payload) => { state.Gemeente = payload }
export const SET_LEEFTIJD = (state, payload) => { state.Leeftijd = payload }
export const SET_DIENSTVERBAND = (state, payload) => { state.Dienstverband = payload }
export const SET_DOELGROEPREGISTER = (state, payload) => { state.Doelgroepregister = payload }
export const SET_DOELGROEP = (state, payload) => { state.Doelgroep = payload }
export const SET_TEGEL = (state, payload) => { state.Tegel = payload }
export const SET_REACH = (state, payload) => { state.Reach = payload }
export const SET_SECTOR = (state, payload) => { state.Sector = payload }
