import RegionsES from '@/services/RegionsES';

export const initRegions = ({ commit, getters, dispatch }, force) => {
    if (!getters.initialized || getters.isExpired() || force) {
        return dispatch('fetchRegions')
            .then(() => commit('SET_INITIALIZED', true))
            .then(() => dispatch('refreshExpirationDate'));
    }
}

export const refreshExpirationDate = ({ commit }) => {
    commit('SET_EXPIRATION_DATE')
}

export const fetchRegions = ({ commit }, payload) => {
    commit('SET_LOADING', true);
    return RegionsES.getRegions()
        .then(response => response.data)
        .then(data => commit('SET_REGIONS', RegionsES.mapSource(data)))
        .finally(() => commit('SET_LOADING', false));
}
