<template>
    <div class="home">
        <v-container fluid>
            <v-row>
                <v-col cols=12>
                    <h2 class="font-weight-black primary--text mb-2">Gebruik de kaart om je regio te kiezen</h2>
                </v-col>
            </v-row>

            <RegionOverview />

            <v-row>
                <v-col cols=12>
                    <p class="text-center">
                        <v-btn class="spring" large @click="$vuetify.goTo('#meer-info')" text icon
                               color="primary">
                            <v-icon large>mdi-arrow-down-circle-outline</v-icon>
                        </v-btn>
                    </p>
                </v-col>
            </v-row>


            <v-row
                id="meer-info"
            >
                <v-col cols=12 md=6>
                    <h3 class="font-weight-black primary--text mb-4">
                        Dennis: de digitale hulp in werkgevers dienstverlening
                    </h3>
                    <p class="font-weight-black">
                        Dennis is hét digitale platform voor adviseurs van arbeidsmarktregio’s die samen met
                        (potentiële) werkgevers werken aan bemiddeling van werkzoekenden. Adviseurs en werkgevers kunnen
                        in Dennis op een snelle en eenvoudige manier instrumenten en leerwerktrajecten bekijken en
                        delen. Het combineert zowel de lokale en regionale instrumenten als de landelijke instrumenten.
                    </p>
                </v-col>

                <v-col cols=12 md=6>
                    <youtube
                        :video-id="'YGjsrZkUOKc'"
                        :player-height="'300px'"
                        :player-width="'100%'"
                        class="mb-6"
                    ></youtube>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols=12 md=6>
                    <div>
                        <h3 class="font-weight-black primary--text mb-4">Zo werk je met Dennis</h3>
                        <p>
                            In Dennis vind je heel eenvoudig geschikte instrumenten. Klik in het hoofdmenu links op
                            ‘Instrumenten’ voor een volledig overzicht van alle landelijke, regionale en lokale
                            instrumenten. Gebruik de filters om een gepersonaliseerd aanbod te krijgen. Klik vervolgens
                            op een instrument voor meer informatie, zoals de voorwaarden en aanmeldinstructie. Hier vind
                            je ook handige links en downloads die met het instrument te maken hebben.
                        </p>
                        <p>
                            Wil je informatie over een specifieke regio? Klik dan hierboven op de gewenste regio.
                        </p>
                        <p>
                            In de
                            <a
                                title="Q&A"
                                href="https://www.samenvoordeklant.nl/sites/default/files/bestandsbijlage/20210526_QA_instrumentengids_Dennis.pdf"
                            >
                                Q&A
                            </a>
                            over Dennis vindt u de meestgestelde vragen en antwoorden over het gebruik van Dennis.
                        </p>
                    </div>
                </v-col>

                <v-col cols=12 md=6>
                    <div>
                        <h3 class="font-weight-black primary--text mb-4">Dennis ondersteunt arbeidsmarktregio’s</h3>
                        <p>
                            Arbeidsmarktregio’s hebben door de wet suwi de opdracht om instrumenten en processen
                            transparant te maken voor werkgevers en waar mogelijk te harmoniseren. Dennis voorziet in
                            deze behoefte. Nieuwsgierig naar ervaringen van arbeidsmarktregio’s lees dan de
                            <a
                                title="artikelen en praktijkverhalen"
                                href="https://www.samenvoordeklant.nl/praktijk/hoe-doet-dennis-het-de-praktijk"
                            >
                                artikelen en praktijkverhalen
                            </a>.
                        </p>
                        <p>
                            Dennis is ontstaan vanuit de vernieuwde wet Suwi die geldt sinds 1 januari 2021.
                            Arbeidsmarkregio’s zijn verplicht om instrumenten en processen transparant aan te bieden aan
                            werkgevers. Dennis voorziet in deze behoefte. Professionals hebben via Dennis inzicht in
                            alle lokale, regionale en landelijke instrumenten voor werkgeversdienstverlening. Doordat de
                            instrumenten openbaar zijn, kunnen arbeidsmarktregio's elkaars aanbod inzien en elkaar
                            inspireren. Een artikel hierover is verschenen in het
                            <a
                                title="VNG Realisatie e-magazine #10 - Hoe gaat het eigenlijk met Dennis?"
                                href="https://publicaties.vngrealisatie.nl/2020/e-magazine/10/hoe-gaat-het-eigenlijk-met-dennis/"
                            >
                                e-magazine van VNG Realisatie
                            </a>.
                            Daarnaast zorgt Dennis voor een eenheid in taal richting werkgevers over gemeente-, regio-
                            en organisatiegrenzen heen.
                        </p>
                        <p>
                            Het platform Dennis is ontwikkeld door VNG Realisatie in samenwerking met
                            arbeidsmarktregio's Zwolle, Midden-Gelderland en Noord-Holland Noord. Ook zijn ketenpartijen
                            als SAM, TNO en het UWV betrokken bij de ontwikkeling. Momenteel wordt Dennis doorontwikkeld
                            samen met deze partijen en bij nieuw aangesloten arbeidsmarktregio's.
                        </p>
                    </div>

                    <div>
                        <h4 class="font-weight-black primary--text mb-4">Contact</h4>
                        <p>
                            Wil je meer weten over Dennis? Neem dan contact op met de beheerder van jouw
                            arbeidsmarktregio. De contactgegevens ervan vind je op de pagina van je regio.
                        </p>
                    </div>

                    <div>
                        <h4 class="font-weight-black primary--text mb-4">UWV arbeidsmarktdashboards</h4>
                        <p>
                            Het UWV heeft dashboards met informatie over de arbeidsmarkt. Elk dashboard heeft een eigen
                            thema waarin een aantal kerncijfers opgenomen zijn. De dashboards zijn te vinden op:
                            <a href="https://www.werk.nl/arbeidsmarktinformatie/dashboards">
                                werk.nl/arbeidsmarktinformatie/dashboards
                            </a>
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import RegionOverview from '@/components/region/RegionOverview';

export default {
    name: 'home',
    components: {
        RegionOverview
    }
}
</script>

<style lang="scss">
.home p {
    color: var(--v-secondary-lighten3);
    font-weight: 500;
    line-height: 1.8;
}
</style>
