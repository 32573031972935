<template>
    <v-list light>
        <v-list-item-group v-if="menu && menu.items.length">
            <v-list-item
                v-for="(item, i) in menu.items"
                :key="i"
                class="font-weight-bold"
                active-class="primary--text"
                :exact="item.exact"
                :to="item.route"
                @click="() => mainMenuNavigation(item)"
            >
                <v-list-item-content class="text-center">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<style lang="scss" scoped>
.v-list {
  background-color: var(--drawer-background) !important;
}
</style>

<script>
import UserBehaviourService from '@/services/UserBehaviourService'

export default {
  name: "MainNavigation",

  data() {
    return {
      menu: {
        items: [
          {
            title: 'Home',
            route: { name: "home" },
            exact: true,
            items: []
          },
          {
            title: 'Instrumenten',
            route: { name: "instrumenten" },
            exact: false,
            items: []
          },
          {
            title: 'Leerwerktrajecten',
            route: { name: "leerwerktrajecten" },
            exact: false,
            items: []
          },
          {
            title: 'Werklandschap',
            route: { name: "werklandschap" },
            exact: true,
            items: []
          },
          // {
          //     title: 'Kennisbank',
          //     route: { name: "definities" },
          //     exact: true,
          //     items: []
          // },
          // {
          //     title: 'Community',
          //     route: { name: "community" },
          //     exact: true,
          //     items: []
          // },
          {
            title: 'Contact',
            route: { name: "contact" },
            exact: true,
            items: []
          }
        ]
      }
    };
  },
  methods: {
    mainMenuNavigation(item) {
      UserBehaviourService.addGeneralAction('main menu item clicked', {
        value: item.title
      })
    }
  }
};
</script>
