<template>
    <div>
        <v-row>
            <v-col cols=6>
                <div
                    v-for="region in firstHalveOfRegions"
                    :key="region.slug"
                >
                    <div
                        :class="['regionListItem', {'active': isRegionActive(region), 'highlighted': isRegionHighlighted(region)}]"
                        @click="$emit('region-clicked', region)"
                    >
                        {{ region.name }}
                    </div>
                </div>
            </v-col>
            <v-col cols=6>
                <div
                    v-for="region in secondHalveOfRegions"
                    :key="region.slug"
                >
                    <div
                        :class="['regionListItem', {'active': isRegionActive(region), 'highlighted': isRegionHighlighted(region)}]"
                        @click="$emit('region-clicked', region)"
                    >
                        {{ region.name }}
                    </div>
                </div>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "RegionList",
    components: {
    },
    props: {
        activeRegion: {
            type: Object,
            default: null,
        },
        highlightedRegion: {
            type: Object,
            default: null,
        }
    },
    computed: {
        ...mapGetters('elastic/regions', [
            'regions',
            'regionsAlphabetically'
        ]),
        refreshStore() {
            return this.$route.query.fresh !== undefined;
        },
        halfAmountOfRegions()
        {
            if (!this.regions || !this.regions.length) {
                return null;
            }
            return Math.ceil(this.regions.length / 2);
        },
        firstHalveOfRegions()
        {
            if (!this.regionsAlphabetically || !this.regionsAlphabetically.length) {
                return null;
            }
            return this.regionsAlphabetically.slice(0, this.halfAmountOfRegions)
        },
        secondHalveOfRegions()
        {
            if (!this.regionsAlphabetically || !this.regionsAlphabetically.length) {
                return null;
            }
            return this.regionsAlphabetically.slice(this.halfAmountOfRegions)
        }
    },
    mounted() {
        this.initRegions(this.refreshStore);
    },
    methods: {
        ...mapActions('elastic/regions', [
            'initRegions',
        ]),
        isRegionActive(region) {
            return this.activeRegion && this.activeRegion.slug === region.slug;
        },
        isRegionHighlighted(region) {
            return this.highlightedRegion && this.highlightedRegion.slug === region.slug;
        },
    },
}
</script>

<style scoped lang="scss">
    .regionListItem {
        position: relative;
        padding: 2px 8px;
        cursor: pointer;
        border-radius: 4px;

        &:before {
            background-color: var(--gray-light);
            border-radius: inherit;
            bottom: 0;
            color: inherit;
            content: "";
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
        }

        &:hover:before,
        &.highlighted:before {
            background-color: var(--gray-dark);
            opacity: 0.08;
        }
        &.active:before {
            background-color: var(--primary);
            opacity: 0.08;
        }
    }
</style>
