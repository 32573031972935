import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import RouteGroup from '../views/RouteGroup'
import DefaultLayout from '@/layouts/DefaultLayout';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {
                path: '',
                name: 'home',
                component: Home
            },
            {
                path: 'arbeidsmarktregio/:region',
                component: RouteGroup,
                children: [
                    {
                        path: '',
                        name: 'arbeidsmarktregio',
                        component: () => import(/* webpackChunkName: "Arbeidsmarktregio" */ '../components/region/RegionDetail.vue')
                    },
                    {
                        path: 'instrumenten',
                        name: 'arbeidsmarktregio_instrumenten',
                        component: () => import(/* webpackChunkName: "Arbeidsmarktregio_instrumenten" */ '../components/region/RegionInstrumentsSearch.vue')
                    },
                ]
            },
            {
                path: 'instrumenten',
                component: RouteGroup,
                children: [
                    {
                        path: '',
                        name: 'instrumenten',
                        component: () => import(/* webpackChunkName: "instrumenten_lijst" */ '../components/instrumenten/InstrumentsSearch.vue')
                    },
                    {
                        path: ':uuid([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})',
                        name: 'instrument_uuid',
                        component: () => import(/* webpackChunkName: "instrument_detail" */ '../components/instrumenten/InstrumentByUuid.vue')
                    },
                    {
                        path: ':slug/:uuid([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})?',
                        name: 'instrument_select',
                        component: () => import(/* webpackChunkName: "instrument_select" */ '../components/instrumenten/InstrumentSelector.vue')
                    }
                ]
            },
            {
                path: 'leerwerktrajecten',
                name: 'leerwerktrajecten',
                component: () => import(/* webpackChunkName: "instrumenten_lijst" */ '../components/instrumenten/LeerwerktrajectInstrumentsSearch.vue')
            },
            {
                path: 'werklandschap',
                component: RouteGroup,
                children: [
                    {
                        path: '',
                        name: 'werklandschap',
                        component: () => import(/* webpackChunkName: "werklandschap_lijst" */ '../components/WerklandschapLijst.vue')
                    },
                    {
                        path: ':tile_key',
                        name: 'werklandschap_detail',
                        component: () => import(/* webpackChunkName: "werklandschap_detail" */ '../components/WerklandschapDetail.vue')
                    }
                ]
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
            },
            {
                path: 'kennisbank',
                name: 'definities',
                component: () => import(/* webpackChunkName: "definities" */ '../views/Kennisbank.vue')
            },
            // {
            //     path: 'community',
            //     name: 'community',
            //     component: () => import(/* webpackChunkName: "community" */ '../views/Community.vue')
            // },
            {
                path: 'dienstverlening',
                name: 'dienstverlening',
                component: () => import(/* webpackChunkName: "dienstverlening" */ '../views/Dienstverlening.vue')
            },
            {
                path: 'links',
                name: 'links',
                component: () => import(/* webpackChunkName: "links" */ '../views/Links.vue')
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router
