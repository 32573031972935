<template>
    <v-img
        src="@/assets/dennis_icon.png"
        lazy-src="@/assets/dennis_icon.png"
        max-width="40"
        max-height="40"
    ></v-img>
</template>

<script>
    export default {
        name: "MenuLogo"
    }
</script>
