import _ from 'lodash';

const environment = process.env.VUE_APP_ENV
const protocol = process.env.VUE_APP_ELASTIC_PROTOCOL;
const path = process.env.VUE_APP_ELASTIC_PATH;
const port = process.env.VUE_APP_ELASTIC_PORT;
const endpoint = process.env.VUE_APP_ELASTIC_ENDPOINT;

export default class ElasticSearch {
    static get protocol()
    {
        const isDevEnvironment = environment === 'development';
        return isDevEnvironment ? 'http' : protocol;
    }

    static get path()
    {
        return path;
    }

    static get port()
    {
        return port;
    }

    static get elasticPath() {
        if (!this.protocol || !this.path || !this.port) {
            return null;
        }
        return `${this.protocol}://${this.path}:${this.port}`;
    }

    static get endpoint() {
        return endpoint ? endpoint : 'api/elastic-data';
    }

    static get localhost() {
        let path = window.location.toString();
        const hasTrailingSlash = path.endsWith('/');
        if (hasTrailingSlash) {
            path = path.slice(0, -1)
        }
        return path;
    }

    static get hostname() {
        if (environment === 'development') {
            return this.localhost.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
        }
        return window.location.hostname;
    }

    static get reactiveBase() {
        if (!this.protocol || !this.hostname || !this.endpoint) {
            throw new Error('Incomplete elastic search parameters');
        }
        return `${this.protocol}://${this.hostname}/${this.endpoint}/`
    }

    static buildQuery(query, defaultQuery)
    {
        return _.mergeWith(defaultQuery, query, (objValue, srcValue) => {
            if (_.isArray(objValue)) {
                return objValue.concat(srcValue);
            }
        });
    }

    static applyIndexPrefix(index) {
        if (!process.env.VUE_APP_ELASTIC_INDEX_PREFIX) {
            return index;
        }
        return `${process.env.VUE_APP_ELASTIC_INDEX_PREFIX}-${index}`;
    }

    static mapSource(data) {
        return data.hits.hits.map(hit => hit._source);
    }
}
