import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const cookie_name = 'anon-id';
export default class AnonymousUserIdentifierService {
    static getIdentifier() {
        let identifier = Cookies.get(cookie_name);
        if (identifier === undefined) {
            this.createIdentifier();
            identifier = Cookies.get(cookie_name);
        }
        return identifier ?? null
    }

    static createIdentifier() {
        const identifier = uuidv4();
        Cookies.set(cookie_name, identifier);
    }
}
