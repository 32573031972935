import TilesES from '@/services/TilesES';

export const initTiles = ({ commit, getters, dispatch }, force) => {
    if (!getters.initialized || getters.isExpired() || force) {
        return dispatch('fetchTiles')
            .then(() => commit('SET_INITIALIZED', true))
            .then(() => dispatch('refreshExpirationDate'));
    }
}

export const refreshExpirationDate = ({ commit }) => {
    commit('SET_EXPIRATION_DATE')
}

export const fetchTiles = ({ commit }, payload) => {
    commit('SET_LOADING', true);
    return TilesES.getTiles()
        .then(response => response.data)
        .then(data => commit('SET_TILES', TilesES.mapSource(data)))
        .finally(() => commit('SET_LOADING', false));
}
