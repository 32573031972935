<template>
    <LeftDrawer
        :show="show"
    >
        <v-row
            class="fill-height"
            style="flex-direction: column;"
        >
            <v-col
                cols=12
                align="center"
                justify="start"
                class="pt-12 pb-8"
                style="flex: 0 1 auto;"
            >
                <MenuLogo />
            </v-col>
            <v-col cols=12 align="center" justify="start" style="flex: 0 1 auto;">
                <MainNavigation />
            </v-col>
            <v-col
                cols=12
                align="center"
                justify="end"
                class="pt-8 pb-12 mt-auto"
                style="flex: 0 1 auto;"
            >
                <VngLogo
                    width="80"
                    height="40"
                />
            </v-col>
        </v-row>
    </LeftDrawer>
</template>

<script>
    import LeftDrawer from '../partials/LeftDrawer';
    import VngLogo from '../partials/VngLogo';
    import MenuLogo from '../partials/MenuLogo';
    import MainNavigation from '@/layouts/partials/MainNavigation';

    export default {
        name: "DefaultLeftDrawer",
        components: {
            MainNavigation,
            LeftDrawer,
            VngLogo,
            MenuLogo
        },
        props: {
            show: {
                type: Boolean,
            }
        }
    }
</script>
