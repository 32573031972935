<template>
    <v-app-bar
        class="top-bar"
        app
        flat
    >
        <slot />
    </v-app-bar>
</template>

<style lang="scss" scoped>
    .top-bar {
        background-color: var(--top-bar-background, #ffffff);
    }
    .v-app-bar.v-app-bar--fixed.top-bar {
        z-index: 600;
    }
</style>

<script>
    export default {
        name: "TopBar"
    }
</script>
