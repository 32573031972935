import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

export default {
    namespaced: true,
    state: () => {
        return {
            Zoeken: '',
            Regio: [],
            Gemeente: [],
            Leeftijd: '',
            Dienstverband: '',
            Doelgroepregister: '',
            Tegel: [],
            Doelgroep: '',
            Reach: [],
            Sector: '',
        }
    },
    mutations,
    actions,
    getters
}
