<template>
    <div
        class="map-container"
        :style="style"
    >
        <div id="regionMap">
            <DoubleBounce v-if="loading" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .map-container {
        display: flex;
        flex-direction: column;
    }
    #regionMap {
        height: 100%;
        flex: 1 0 auto;
        &.leaflet-container {
            background-color: transparent;
        }
    }
</style>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import leaflet from 'leaflet';
    import GeoAPI from '@/services/GeoAPI';
    import DoubleBounce from '@/components/loader/DoubleBounce';

    export default {
        name: 'RegionMap',
        components: { DoubleBounce },
        data: function () {
            return {
                map: null,
                bounds: [[50.752, 3.367], [53.556, 7.226]],
                loading: false,
            }
        },
        props: {
            height: {
                type: String,
            }
        },
        computed: {
            ...mapGetters('elastic/regions', [
                'regions',
                'regionByName',
                'regionByCode',
            ]),
            minHeight() {
                return this.height || '300px';
            },
            style() {
                return {
                    minHeight: this.minHeight
                };
            },
            refreshStore() {
                return this.$route.query.fresh !== undefined;
            }
        },
        created() {
            window.addEventListener("resize", this.fitMap);
        },
        destroyed() {
            window.removeEventListener("resize", this.fitMap);
        },
        mounted() {
            this.initMap();
        },
        methods: {
            ...mapActions("elastic/regions", [
                "initRegions"
            ]),
            initMap() {
                this.map = leaflet.map('regionMap', {
                    attributionControl: false,
                    zoomControl: false,
                    touchZoom: false,
                    doubleClickZoom: false,
                    dragging: false,
                    maxBounds: this.bounds,
                    maxBoundsViscosity: 1,
                    scrollWheelZoom: false,
                    zoomSnap: 0.25,
                });
                this.initRegions(this.refreshStore)
                    .then(() => this.fetchAllGeoData())
                    .then(() => this.fitMap());
            },

            fitMap() {
                this.map.fitBounds(this.bounds);
            },

            async fetchAllGeoData() {
                const batchSize = 100;
                this.loading = true;
                const response = await GeoAPI.fetchRegionData(0, batchSize);
                this.loading = false;
                response.data.features.forEach(feature => this.addRegionToMap(feature));

                const totalFeatures = response.data.totalFeatures;
                const iterations = totalFeatures / batchSize;

                for (let i = 1; i < iterations; i++) {
                    const start = batchSize * i;
                    this.loading = true;
                    const response = await GeoAPI.fetchRegionData(start, batchSize);
                    this.loading = false;
                    response.data.features.forEach(feature => this.addRegionToMap(feature));
                }
            },

            addRegionToMap(feature) {
                let code = feature.properties.statcode;
                if (code === 'AM99') {
                    // Niet in te delen => Gorinchem
                    code = 'AM35';
                }
                const region = this.regionByCode(code);

                leaflet.geoJSON(feature.geometry, {
                    style: {
                        fillColor: region ? region.color : '#333333',
                        fillOpacity: 0.5,
                        weight: 0.6, //1.2,
                        color: '#ffffff',
                    },
                    onEachFeature: (geometry, layer) => layer.on({
                        click: () => {
                            if (region) {
                                this.$emit('region-clicked', region);
                            }
                        },
                        mouseover:  () => {
                            if (region) {
                                this.$emit('region-hover', region);
                            }
                        },
                    }),
                })
                    .bindTooltip(region ? region.name : feature?.properties?.statname, {
                        className: 'regionLabel'
                    })
                    .addTo(this.map);
            }
        }
    }
</script>
