import axios from 'axios';
import ElasticSearch from './ElasticSearch';

const index_tiles = 'tiles';

export default class EnvironmentsES extends ElasticSearch {
    static get indexTiles() {
        return this.applyIndexPrefix(index_tiles);
    }

    static getTiles() {
        const index = EnvironmentsES.indexTiles;
        const searchParams = {
            size: 100,
        };
        return axios.post(`/${this.endpoint}/${index}/_search`, searchParams);
    }
}
