import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import aanbod from './modules/aanbod';
import elastic from './modules/elastic';
import filters from './modules/filters';
import version from './modules/version';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        aanbod,
        elastic,
        filters,
        version
    },
    plugins: [vuexLocal.plugin]
})
