<template>
    <v-navigation-drawer
        app
        floating
        :value="show"
        class="navigation-drawer-left fill-height"
    >
        <slot />
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: "LeftDrawer",
        props: {
            show: {
                type: Boolean,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-navigation-drawer.navigation-drawer-left {
        background-color: var(--drawer-background);
        z-index: 601;
    }
</style>
