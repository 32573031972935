export const aanbod = state => state.aanbod
export const instrumentIsAangeboden = state => instrumentId => state.aanbod.map(instrument => instrument.uuid).includes(instrumentId)
export const isExpired = (state) => () => {
  const expirationDate =
    state.expirationDate instanceof Date
      ? state.expirationDate
      : new Date(state.expirationDate)

  return expirationDate < new Date()
}
