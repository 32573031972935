<template>
    <div v-if="region !== null">
        <v-row>
            <v-col sm=12 md=6>
                <h4 class="title font-weight-black">
                    Regio <span class="primary--text">{{ region.name }}</span>
                </h4>
            </v-col>
        </v-row>
        <div>
            <v-row
                class="mb-4"
                dense
            >
                <v-col cols="12" sm="6" md="3"
                    v-for="township in region.townships"
                    :key="township.slug"
                >
                    {{ township.name }}
                </v-col>
            </v-row>
            <PrimaryButton
                @click="goToRegion"
            >
                Arbeidsmarktregio verkennen
            </PrimaryButton>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import _ from 'lodash';
    import PrimaryButton from '@/components/styled/PrimaryButton';

    export default {
        name: 'RegionSummary',
        components: { PrimaryButton },
        props: {
            region: {
                type: Object,
                default: null,
            }
        },
        data: () => {
            return {
                lo: _,
            }
        },
        methods: {
            goToRegion() {
                // Wordt vanuit het ArbeidsmarktregioInstrumenten component geregeld
                // this.setRegio([this.region.name]);
                this.$router.push({
                    name: 'arbeidsmarktregio',
                    params: { region: this.region.slug }
                });
            },
            ...mapActions('filters', [
                'setRegio'
            ])
        }
    }
</script>
