import axios from 'axios';
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
    maxAge: 8 * 60 * 1000,
    readHeaders: false,
    exclude: {
        query: false,
    },
});

export default class GeoAPI {
    static fetchKadasterTownshipData(start = 0, count = 10) {
        return axios.get('https://geodata.nationaalgeoregister.nl/bestuurlijkegrenzen/wfs', {
            adapter: cache.adapter,
            params: {
                request: 'GetFeature',
                service: 'WFS',
                version: '2.0.0',
                typeName: 'gemeenten',
                outputFormat: 'json',
                count: count,
                startindex: start,
                srsName: 'EPSG:4326',
            }
        });
    }

    static fetchTownshipData(start = 0, count = 10) {
        return axios.get('https://geodata.nationaalgeoregister.nl/cbsgebiedsindelingen/wfs', {
            adapter: cache.adapter,
            params: {
                request: 'GetFeature',
                service: 'WFS',
                version: '2.0.0',
                typeName: 'cbsgebiedsindelingen:cbs_gemeente_2022_gegeneraliseerd',
                outputFormat: 'json',
                count: count,
                startindex: start,
                srsName: 'EPSG:4326',
            }
        });
    }

    static fetchRegionTownshipData(region, start = 0, count = 10) {
        // The cql filter needs sinqlequotes to be escaped with an extra quote
        // const cql_filter = region.townships.map(t => `statnaam='${t.name.replace('\'', '\'\'')}'`).join(' OR ');

        const filters = region.townships.map(t => `<PropertyIsEqualTo><PropertyName>statnaam</PropertyName><Literal>${t.name}</Literal></PropertyIsEqualTo>`);
        const filter = `<Filter><OR>${filters.join('')}</OR></Filter>`;

        return axios.get('https://service.pdok.nl/cbs/gebiedsindelingen/2023/wfs/v1_0', {
            adapter: cache.adapter,
            params: {
                request: 'GetFeature',
                service: 'WFS',
                version: '2.0.0',
                typeName: 'cbsgebiedsindelingen:gemeente_gegeneraliseerd',
                outputFormat: 'json',
                count: count,
                startindex: start,
                srsName: 'EPSG:4326',
                // cql_filter: filter
                filter: filter
            }
        });

        // filter=<Filter><PropertyIsEqualTo matchCase="true"><PropertyName>statnaam</PropertyName><Literal>Almere</Literal></PropertyIsEqualTo></Filter>
        // filter=<Filter><PropertyIsEqualTo matchCase="true"><ValueReference>statnaam</ValueReference><Literal>Almere</Literal></PropertyIsEqualTo></Filter>
        // return axios.get('https://geodata.nationaalgeoregister.nl/cbsgebiedsindelingen/wfs', {
        //     adapter: cache.adapter,
        //     params: {
        //         request: 'GetFeature',
        //         service: 'WFS',
        //         version: '2.0.0',
        //         typeName: 'cbsgebiedsindelingen:cbs_gemeente_2022_gegeneraliseerd',
        //         outputFormat: 'json',
        //         count: count,
        //         startindex: start,
        //         srsName: 'EPSG:4326',
        //         cql_filter: filter
        //     }
        // });
    }

    static fetchRegionData(start = 0, count = 10) {
        return axios.get('https://service.pdok.nl/cbs/gebiedsindelingen/2022/wfs/v1_0', {
            adapter: cache.adapter,
            params: {
                request: 'GetFeature',
                service: 'WFS',
                version: '2.0.0',
                typeName: 'cbsgebiedsindelingen:arbeidsmarktregio_gegeneraliseerd',
                outputFormat: 'json',
                count: count,
                startindex: start,
                srsName: 'EPSG:4326',
            }
        });
        // return axios.get('https://geodata.nationaalgeoregister.nl/cbsgebiedsindelingen/wfs', {
        //     adapter: cache.adapter,
        //     params: {
        //         request: 'GetFeature',
        //         service: 'WFS',
        //         version: '2.0.0',
        //         typeName: 'cbsgebiedsindelingen:cbs_arbeidsmarktregio_2022_gegeneraliseerd',
        //         outputFormat: 'json',
        //         count: count,
        //         startindex: start,
        //         srsName: 'EPSG:4326',
        //     }
        // });
    }
}
