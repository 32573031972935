<template>
    <RightDrawer
        :show="show"
    >
        <v-list-item class="primary" dark>
            <v-icon small class="mr-2">mdi-briefcase</v-icon>
            <v-list-item-content>
                <v-list-item-title>Aanbod Werkgever</v-list-item-title>
            </v-list-item-content>
            <v-btn
                text
                icon
                @click.stop="$emit('toggle')"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-container>
            <v-row>
                <v-col cols=12>
                    <v-form v-model="validEmail" v-if="aanbod.length">
                        <h3 class="primary--text font-weight-black subtitle-2">
                            Contactveld
                        </h3>
                        <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="E-mail"
                            required
                        ></v-text-field>

                        <h3 class="primary--text font-weight-black subtitle-2">
                            Gekozen regelingen
                        </h3>
                        <v-list dense>
                            <v-list-item
                                v-for="instrument in aanbod"
                                :key="instrument.uuid"
                                class="aanbod-list-item"
                                dense
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-black subtitle-2">
                                        {{ instrument.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-btn @click="deleteAanbod(instrument)" small right icon>
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                            </v-list-item>
                        </v-list>
                        <v-btn
                            @click="adviesVersturen"
                            :disabled="!validEmail"
                            color="primary"
                            class="pl-2 mt-4"
                        >
                            <v-icon small class="mr-2 pa-2">mdi-briefcase</v-icon>
                            Advies versturen
                        </v-btn>
                    </v-form>
                    <v-alert
                        v-else
                        type="warning"
                    >
                        Stel eerst een aanbod van instrumenten samen
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
    </RightDrawer>
</template>

<style lang="scss" scoped>
.aanbod-list-item.v-list-item {
    border-bottom: 1px solid var(--border-secondary);
    padding: 0 4px;

    &:first-child {
        border-top: 1px solid var(--border-secondary);
    }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import RightDrawer from '../partials/RightDrawer';
import router from '@/router';
import UserBehaviourService from '@/services/UserBehaviourService';

export default {
    name: "DefaultRightDrawer",
    components: {
        RightDrawer
    },
    props: {
        show: {
            type: Boolean,
        },
    },
    data: () => ({
        email: '',
        validEmail: false,
        emailRules: [
            v => !!v || 'Voer a.u.b. een emailadres in',
            v => /.+@.+/.test(v) || 'Voer een geldig emailadres in'
        ],
    }),
    computed: {
        ...mapGetters('aanbod', [
            'aanbod'
        ])
    },
    mounted() {
        this.refreshAanbod();
    },
    methods: {
        ...mapActions('aanbod', [
            'refreshAanbod',
            'resetAanbod',
            'deleteAanbod'
        ]),
        adviesVersturen() {
            window.open(`mailto:${this.email}?subject=Besproken instrumenten&body=${this.getEmailBody()}`);
            UserBehaviourService.addSearchAction('send offer');
            this.resetAanbod();
        },
        getEmailBody() {
            const host = window.location.host;
            const instrumentLinks = this.aanbod.map(function (instrument) {
                const href = 'https://' + host + router.resolve({ name: 'instrument_select', params: { slug: instrument.slug, uuid: instrument.uuid }}).href;
                return `${instrument.name} \n\n ${href}`;
            });

            return encodeURIComponent(instrumentLinks.join('\n\n\n'));
        }
    }
}
</script>
