export const setZoeken = ({ commit }, payload) => { commit('SET_ZOEKEN', payload) }
export const setRegio = ({ commit }, payload) => { commit('SET_REGIO', payload) }
export const setGemeente = ({ commit }, payload) => { commit('SET_GEMEENTE', payload) }
export const setLeeftijd = ({ commit }, payload) => { commit('SET_LEEFTIJD', payload) }
export const setDienstverband = ({ commit }, payload) => { commit('SET_DIENSTVERBAND', payload) }
export const setDoelgroepregister = ({ commit }, payload) => { commit('SET_DOELGROEPREGISTER', payload) }
export const setDoelgroep = ({ commit }, payload) => { commit('SET_DOELGROEP', payload) }
export const setTegel = ({ commit }, payload) => { commit('SET_TEGEL', payload) }
export const setReach = ({ commit }, payload) => { commit('SET_REACH', payload) }
export const setSector = ({ commit }, payload) => { commit('SET_SECTOR', payload) }
