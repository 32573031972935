export const initialized = state => state.initialized
export const loading = state => state.loading
export const isExpired = (state) => () => {
    const expirationDate =
      state.expirationDate instanceof Date
        ? state.expirationDate
        : new Date(state.expirationDate)

    return expirationDate < new Date()
}
export const regions = state => state.regions
export const regionsAlphabetically = state => state.regions.sort((a, b) => {
    if (a.name === b.name) {
        return 0;
    }
    return a.name < b.name ? -1 : 1;
})
export const regionByName = state => name => state.regions.find(region => region.name === name)
export const regionBySlug = state => slug => state.regions.find(region => region.slug === slug)
export const regionByCode = state => code => state.regions.find(region => region.code === code)
