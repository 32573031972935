<template>
    <div>
        <v-row>
            <v-col cols=12 md=6>
                <RegionMap
                    class="region-map"
                    @region-clicked="onRegionClick"
                    @region-hover="onRegionHover"
                />
            </v-col>

            <v-col cols=12 md=6>
                <div class="mb-8">
                    <header>
                        <h4 class="title font-weight-black mb-2">
                            Arbeidsmarktregio's
                        </h4>
                    </header>
                    <RegionList
                        :activeRegion="selectedRegion"
                        :highlightedRegion="hoveredRegion"
                        @region-clicked="onRegionClick"
                    />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=12>
                <RegionSummary
                    id="region-summary"
                    :region="selectedRegion"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RegionMap from '@/components/maps/RegionMap';
import RegionSummary from '@/components/region/RegionSummary';
import RegionList from '@/components/region/RegionList';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "RegionOverview",
    components: {
        RegionList,
        RegionSummary,
        RegionMap
    },
    data() {
        return {
            selectedRegion: null,
            hoveredRegion: null
        }
    },
    computed: {
        ...mapGetters('elastic/regions', [
            'regions'
        ]),
        refreshStore() {
            return this.$route.query.fresh !== undefined;
        },
    },
    mounted() {
        this.initRegions(this.refreshStore)
            .then(() => this.selectedRegion = this.regions[0]);
    },
    methods: {
        ...mapActions('elastic/regions', [
            'initRegions',
        ]),
        onRegionClick(region) {
            if (region) {
                this.selectedRegion = region;
                this.$vuetify.goTo('#region-summary')
            }
        },
        onRegionHover(region) {
            if (region) {
                this.hoveredRegion = region;
            }
        },
    },
}
</script>

<style scoped lang="scss">
.region-map {
    height: 100%;
}
</style>
