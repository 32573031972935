<template>
    <TopBar
        v-on="$listeners"
    >
        <v-app-bar-nav-icon
            class="hidden-lg-and-up"
            @click.stop="$emit('toggle-drawer-left')"
        ></v-app-bar-nav-icon>
        <p class="ml-2 mb-0 headline hidden-sm-and-down font-weight-black">
            Dennis
            <span class="text-truncate ml-2 hidden-sm-and-down font-weight-regular font-italic primary--text">
                dé werkgevers instrumentengids
            </span>
        </p>

        <v-spacer></v-spacer>
        <template v-if="enableShare">
            <Badge
                v-if="aanbod.length > 0"
                :content="aanbod.length"
                overlap
            >
                <PrimaryButtonInversed
                    class="text-transform-none pl-xs-0 pl-md-4"
                    @click.stop="openPersonalOffer"
                >
                    <span class="hidden-sm-and-down mr-2">
                        Aanbod Werkgever
                    </span>
                    <v-icon small>mdi-briefcase</v-icon>
                </PrimaryButtonInversed>
            </Badge>
            <PrimaryButtonInversed
                v-else
                class="text-transform-none pl-xs-0 pl-md-4"
                @click.stop="openPersonalOffer"
            >
                <span class="hidden-sm-and-down mr-2">
                    Aanbod Werkgever
                </span>
                <v-icon small>mdi-briefcase</v-icon>
            </PrimaryButtonInversed>
        </template>
    </TopBar>
</template>

<script>
    import TopBar from '../partials/TopBar';
    import PrimaryButtonInversed from '@/components/styled/PrimaryButtonInversed';
    import { mapGetters } from 'vuex';
    import Badge from '@/components/styled/Badge';
    import UserBehaviourService from '@/services/UserBehaviourService';

    export default {
        name: "DefaultTopBar",
        components: {
            Badge,
            PrimaryButtonInversed,
            TopBar
        },
        data() {
            return {
                enableShare: true,
            }
        },
        computed: {
            ...mapGetters('aanbod', [
                'aanbod'
            ]),
        },
        methods: {
            openPersonalOffer()
            {
                UserBehaviourService.addSearchAction('open offer');
                this.$emit('toggle-drawer-right');
            }
        }
    }
</script>
