export const updateVersion = ({ commit }) => {
    commit('SET_VERSION', process.env.VUE_APP_VERSION);
    commit('SET_UPDATED_AT', new Date());
}

export const checkVersion = ({ commit, getters, dispatch }) => {
    if (getters.isBehind()) {
        Promise.all([
            dispatch('elastic/providers/initProviders', true, { root: true }),
            dispatch('elastic/regions/initRegions', true, { root: true }),
            dispatch('elastic/tiles/initTiles', true, { root: true }),
            dispatch('elastic/townships/initTownships', true, { root: true })
        ]).finally(() => dispatch('updateVersion'));
    }
}
