<template>
    <v-navigation-drawer
        app
        :value="show"
        temporary
        right
    >
        <slot />
    </v-navigation-drawer>
</template>

<style lang="scss" scoped>
    .v-navigation-drawer--right {
        z-index: 601;
    }
</style>

<script>
    export default {
        name: "RightDrawer",
        props: {
            show: {
                type: Boolean,
            },
        },
    }
</script>
