import axios from 'axios';
import ElasticSearch from './ElasticSearch';

const index_townships = 'townships';

export default class TownshipsES extends ElasticSearch {
    static get indexTownships() {
        return this.applyIndexPrefix(index_townships);
    }

    static getTownships() {
        const index = TownshipsES.indexTownships;
        const data = {
            from: 0,
            size: 400
        };

        return axios.post(`/${this.endpoint}/${index}/_search`, data);
    }

    static getTownship(slug) {
        const index = TownshipsES.indexTownships;
        const data = {
            query: {
                bool: {
                    must: [
                        { match: { slug: slug } }
                    ]
                }
            }
        };

        return axios.post(`/${this.endpoint}/${index}/_search`, data);
    }
}
